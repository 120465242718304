
import { defineComponent } from 'vue';
import globals from '../../../helpers/globals';
import FormButton from '../../../components/forms/Button.vue';
import FormInput from '../../../components/forms/Input.vue';
import AccountDataService from '../../../services/AccountDataService';

export default defineComponent({
  name: 'ForgotPassword',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      form: {
        serverError: null,
        serverSuccess: null,
        inputs: {
          email: {
            value: null,
            rule: 'required|email',
            error: null,
          },
        } as {[key: string]: any},
      },
    };
  },
  created() {
    this.$store.dispatch('isLoading', true);

    this.init();
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    hasToken() {
      return this.$route.params.token && this.$route.params.email;
    },
    /* COMPONENT INITIALIZER */
    init() {
      if (this.hasToken()) {
        this.form.inputs.email.value = this.$route.params.email;

        this.form.inputs.password = {
          value: null,
          rule: 'required',
          error: null,
        } as {[key: string]: any};

        this.form.inputs.password_confirm = {
          value: null,
          rule: 'required|same:password',
          error: null,
        } as {[key: string]: any};
      }

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleSideBar', false);
      this.$store.dispatch('toggleTopBar', false);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) { /* */ },
    passwordContainsDigits() {
      return this.form.inputs.password.value && (/[0-9]/.test(this.form.inputs.password.value.trim()));
    },
    passwordContainsUppercaseLetters() {
      return this.form.inputs.password.value && (/[A-Z]/.test(this.form.inputs.password.value.trim()));
    },
    passwordContainsLowercaseLetters() {
      return this.form.inputs.password.value && (/[a-z]/.test(this.form.inputs.password.value.trim()));
    },
    passwordContains8Caracters() {
      return this.form.inputs.password.value && this.form.inputs.password.value.length >= 8;
    },
    securityLevel() {
      let level = 0;

      if (this.passwordContainsDigits()) level += 3;
      if (this.passwordContainsUppercaseLetters()) level += 3;
      if (this.passwordContainsLowercaseLetters()) level += 3;
      if (this.passwordContains8Caracters()) level += 3;

      return level;
    },
    goToNextStep(event: any) {
      const vm = this;

      event.preventDefault();

      vm.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          if (!this.hasToken()) {
            AccountDataService.resetPasswordRequest(this.form.inputs.email.value, this.$store.state.recaptcha)
              .then((response: any) => {
                vm.form.inputs.email.value = null;

                vm.$store.dispatch('isLoading', false);
              }).catch((e: any) => {
                vm.form.serverSuccess = null;
                vm.$store.dispatch('isLoading', false);
              });
          } else {
            AccountDataService.resetPassword(this.$route.params.token as string, this.form.inputs.email.value, this.form.inputs.password.value, this.form.inputs.password_confirm.value, this.$store.state.recaptcha)
              .then((response: any) => {
                vm.form.inputs.email.value = null;

                vm.$store.dispatch('isLoading', false);
                this.$router.push({
                  name: 'LoginBase',
                });
              }).catch((e: any) => {
                vm.form.serverSuccess = null;
                vm.$store.dispatch('isLoading', false);
              });
          }
        });
    },
    disabledSubmitButton() {
      let error = false;

      Object.values(this.form.inputs).every((element: any) => {
        if ((element.value === null && element.rule.includes('required')) || element.error !== null) {
          error = true;
          return false;
        }

        return true;
      });

      if (this.hasToken() && this.securityLevel() < 12) error = true;

      return error;
    },
    goToSegmentSelection() {
      this.$router.push({
        name: 'LoginBase',
      });
    },
  },
});
